//@ts-check

import { settingsStore } from "./settings.js"
import { mutate } from "./libs/tstorex/recipes.js"

export const player = /** @type {HTMLMediaElement} */ (document.getElementById("player"))
const playerSrc = /** @type {HTMLSourceElement} */ (document.getElementById("source"))

/**
 * @param {File|undefined} mediaFile
 * @param {string} binaryStringType
 */
export const setMedia = (/** @type {File|undefined} */ mediaFile, binaryStringType = "") => {
	player.hidden = !mediaFile
	if (!mediaFile) {
		return
	}
	mutate(/**@type {any}*/ (settingsStore), (settings) => {
		settings.audio.fileName = mediaFile.name
	})
	const url = binaryStringType
		? URL.createObjectURL(new Blob([mediaFile], { type: binaryStringType }))
		: URL.createObjectURL(mediaFile)
	playerSrc.src = url
	playerSrc.type = binaryStringType || mediaFile.type
	player.loop = false
	player.load()
}

document.addEventListener("keydown", (event) => {
	if (event.key == "Escape" && !player.hidden) {
		player.paused ? player.play() : player.pause()
	}
})
