//@ts-check
import { settingsStore } from "./settings.js"
import { mutate } from "./libs/tstorex/recipes.js"
import { TranscriptLine } from "./customElements/TranscriptLine.js"
import { player } from "./player.js"

const article = /** @type {HTMLElement} */ (document.querySelector("article"))
const transciptOutput = /** @type {HTMLElement} */ (document.getElementById("transcript-output"))
const timestampRegExp = /^\[([0-9:\.\s>-]{29})\]/

export const container = transciptOutput

export const findTranscriptLine = (/** @type {number} */ time) => {
	const line = TranscriptLine.getLines().find(({ start, end }) => start <= time && end > time)
	return line
}
export const getHiglightedTranscriptLine = () => {
	return /** @type {TranscriptLine|null} */ (transciptOutput.querySelector("transcript-line.highlight"))
}
export const highlightTranscriptLine = (/** @type {TREx.TranscriptLine} */ line, grabFocus = false) => {
	if (line.classList.contains("highlight")) {
		return
	}
	line.classList.add("highlight")
	const highlighted = Array.from(document.querySelectorAll("transcript-line.highlight"))
	highlighted.forEach((el) => el !== line && el.classList.remove("highlight"))
	grabFocus && line.grabFocus()
	if (document.activeElement !== transciptOutput) {
		// @ts-expect-error scrollIntoViewIfNeeded is not in the spec
		if (line.scrollIntoViewIfNeeded) {
			// @ts-expect-error scrollIntoViewIfNeeded is not in the spec
			line.scrollIntoViewIfNeeded(false)
		} else {
			line.scrollIntoView({ behavior: "smooth", block: "center" })
		}
	}
}
export const highlightTranscriptLineAt = (/** @type {number} */ time) => {
	time = Math.round(time * 100) / 100
	const line = findTranscriptLine(time)
	line && !line.classList.contains("highlight") && highlightTranscriptLine(line)
}

/** @param {number} seconds */
const secondsToTSPart = (seconds) => new Date(seconds * 1000).toISOString().slice(11, 23)
/**
 *
 * @param {{
 *     start?: number
 *     end?: number
 *     text?: string
 * }} options text parameter should contain timestamp unless start and end are passed
 */
export const newTranscriptLine = (options) => {
	const { start, end, text } = options || {}
	const line = /** @type {TREx.TranscriptLine} */ (new TranscriptLine())
	if (text?.match(timestampRegExp)) {
		// there's a timestamp at the beginning of the text
		line.dataset.ts = text.slice(1, 30)
		line.insertAdjacentHTML("beforeend", text.slice(32).trimEnd())
	} else if (start !== undefined && end !== undefined) {
		line.dataset.ts = `${secondsToTSPart(start)} --> ${secondsToTSPart(end)}`
	} else {
		console.error("Can't create a Transcript line without a timestamp.")
		return null
	}
	return line
}

/** @param {string} text */
export const appendTranscriptLine = (text) => {
	const tl = newTranscriptLine({ text })
	if (!tl) {
		return
	}
	transciptOutput.appendChild(tl)
	if (document.activeElement !== transciptOutput) {
		transciptOutput.scrollTop = transciptOutput.scrollHeight
		article.scrollTop = article.scrollHeight
	}
}

export const toggleLineNumbers = (/** @type {boolean|undefined} */ on = undefined) => {
	transciptOutput.classList.toggle("display-line-numbers", on)
}

export const toggleTsFull = (/** @type {boolean|undefined} */ on = undefined) => {
	const wasFull = transciptOutput.classList.contains("ts-full")
	transciptOutput.classList.toggle("ts-full", on)
	wasFull !== on && TranscriptLine.getLines().forEach((line) => line.render())
}
export const toggleSpeakersDisplay = (/** @type {boolean|undefined} */ on = undefined) => {
	const prevState = transciptOutput.classList.contains("display-speakers")
	transciptOutput.classList.toggle("display-speakers", on)
	prevState !== on && TranscriptLine.getLines().forEach((line) => line.render())
}

export const toggleConfidenceDisplay = (/** @type {boolean|undefined} */ on = undefined) => {
	container.classList.toggle("display-prob", on)
}

export const copySafe = () => {
	const lines = TranscriptLine.getLines()
	const { text, html } = lines.reduce(
		(prev, /** @type {TranscriptLine} */ line) => {
			const [text, html] = line.toTextAndHTML()
			prev.text += text + "\n"
			prev.html += html + "\n"
			return prev
		},
		{ text: "", html: "" }
	)
	const blobText = new Blob([text], { type: "text/plain" })
	const blobHtml = new Blob([html], { type: "text/html" })
	navigator.clipboard.write([
		new ClipboardItem({
			"text/html": blobHtml,
			"text/plain": blobText,
		}),
	])
}
/**
 * add a listener to line click event
 * @param {(line: TranscriptLine, evt?: MouseEvent)=>void} cb
 * @returns {()=>void} remove listener
 */
export const onTranscriptLineClick = (cb) => {
	const listener = (/** @type {MouseEvent} */ event) => {
		// @ts-expect-error
		const line = event.target?.closest("transcript-line")
		line && cb(line, event)
	}
	transciptOutput.addEventListener("click", listener)
	return () => transciptOutput.removeEventListener("click", listener)
}

//#region  load/save management

// function base64ToBytes(base64) {
// 	const binString = atob(base64)
// 	return new TextDecoder().decode(Uint8Array.from(binString, (m) => m.codePointAt(0)))
// }

// function bytesToBase64(bytes) {
// 	const binString = Array.from(new TextEncoder().encode(bytes), (x) => String.fromCodePoint(x)).join("")
// 	return btoa(binString)
// }

/**
 * create a download link and trigger click
 * @param {Blob} blob
 * @param {string} filename
 */
export const triggerDownload = (blob, filename) => {
	const elem = document.createElement("a")
	elem.href = URL.createObjectURL(blob)
	elem.download = filename
	document.body.appendChild(elem)
	elem.click()
	setTimeout(() => document.body.removeChild(elem), 10)
}

export const saveTranscript = async () => {
	// get Transcript content
	const transcript = Array.from(transciptOutput.children)
		.map((line) => (line instanceof TranscriptLine ? line.toRawHTML() : ""))
		.join("")
	const settings = settingsStore.get()
	// const audio = await fetch(playerSrc.src).then((res) => res.blob())
	// const type = audio.type
	const language = settings.audio.lang
	const { model, threads } = settings.whisper
	const { formatVersion, fileName } = settings.save
	// const data = JSON.stringify({ transcript, audio: bytesToBase64(await audio.text()), type, language, model })
	const data = JSON.stringify(
		/** @type {TREx.SaveData} */ {
			formatVersion,
			language,
			model,
			threads,
			speakers: settings.speakers,
			transcript,
		}
	)
	let blob = new Blob([data], { type: "application/json;charset=utf-8" })
	// const compressedStream = blob.stream().pipeThrough(new CompressionStream("gzip"))
	// const response = await new Response(compressedStream)
	// blob = await response.blob()
	triggerDownload(blob, fileName || `${settings.audio.fileName.replace(/\.[^.]*$/, ".")}transcript.json`)
}

/** @param {File|undefined} file */
export const loadTranscript = async (file) => {
	return new Promise((resolve, reject) => {
		if (!file) {
			reject("No file selected")
			return
		}
		const reader = new FileReader()
		reader.onload = async (event) => {
			if (!event.target?.result) {
				return
			}
			// console.log(event.target.result)
			// const decompressStream = new DecompressionStream("gzip")
			// const decompressedStream = ReadableStream.from([event.target.result]).stream().pipeThrough(decompressStream)
			/** @type {TREx.SaveData} */
			let data
			try {
				data = JSON.parse(/** @type {string}*/ (event.target.result))
			} catch (e) {
				reject(e)
				return
			}
			TranscriptLine.bulkUpdate(() => {
				// @TODO check format version and migrate if needed
				mutate(/** @type {any} */ (settingsStore), (state) => {
					state.save.fileName = file.name
					data.language && (state.audio.lang = data.language)
					data.model && (state.whisper.model = data.model)
					data.threads && (state.whisper.threads = data.threads)
					if (Array.isArray(data.speakers)) {
						data.speakers = data.speakers.reduce(
							(/** @type {Record<string, TREx.Speaker>}*/ acc, /** @type {TREx.Speaker}*/ speaker, id) => {
								acc[id] = { ...speaker, id: "" + id }
								return acc
							},
							{}
						)
					}
					state.speakers = data.speakers || {}
				})
				// transcript lines must be inserted after speakers are set
				transciptOutput.innerHTML = data.transcript
			}, true)
			// setAudio(base64ToBytes(data.audio), data.type)
			resolve(void 0)
		}
		reader.readAsText(file)
	})
}
//#endregion  load/save management

//#region keyboard shortcuts
/** @param {(evt:Event)=>void} cb*/

const prevDeflt = (cb) => (/** @type {Event} */ evt) => {
	evt.preventDefault()
	cb(evt)
}
const clickLine = () => {
	TranscriptLine.getCaretLine()?.click()
}
/**
 * @param {number} speakerIndex
 */
const tagLine = (speakerIndex) => {
	const line = getHiglightedTranscriptLine()
	if (!line) {
		return
	}
	let speakerId = "null"
	if (speakerIndex !== 0) {
		const speakers = settingsStore.get().speakers
		const speakerIds = Object.keys(speakers)
		speakerId = String(speakerIds[speakerIndex - 1] || null)
	}
	TranscriptLine.bulkUpdate(() => {
		if (line.dataset.speakerId === speakerId) {
			line.unbindSpeaker()
		} else {
			line.bindSpeaker(speakerId)
		}
	}, true)
}
// @TODO on supr / backspace, or return should use line editor instead of default behavior
const editKeysRegExp =
	/^([^\P{Cc}\P{Cn}\P{Cs}]|Backspace|Clear|Copy|CrSel|Cut|Del(?:ete)?|EraseEof|ExSel|Insert|Paste|Redo|Undo)$/i
/** @type {Record<'keydown'|'keyup', Record<string, (evt:KeyboardEvent) => void>>} */
const keyBindingsCtrl = {
	keydown: {
		"Ctrl+s": prevDeflt(saveTranscript),
		"Ctrl+C": prevDeflt(copySafe),
		"Ctrl+1": prevDeflt(() => tagLine(1)),
		"Ctrl+2": prevDeflt(() => tagLine(2)),
		"Ctrl+3": prevDeflt(() => tagLine(3)),
		"Ctrl+4": prevDeflt(() => tagLine(4)),
		"Ctrl+5": prevDeflt(() => tagLine(5)),
		"Ctrl+6": prevDeflt(() => tagLine(6)),
		"Ctrl+7": prevDeflt(() => tagLine(7)),
		"Ctrl+8": prevDeflt(() => tagLine(8)),
		"Ctrl+9": prevDeflt(() => tagLine(9)),
		"Ctrl+0": prevDeflt(() => tagLine(0)),
		"Ctrl+/": prevDeflt(() => {
			if (document.activeElement !== transciptOutput) {
				console.warn("Split line can only be triggered when the transcript is focused.")
				return
			}
			const line = TranscriptLine.getCaretLine()
			line && line.splitAt(player.currentTime)
		}),
	},
	keyup: {
		ArrowUp: prevDeflt(() => {
			if (document.activeElement === transciptOutput) {
				return clickLine()
			}
			const line = getHiglightedTranscriptLine()
			line?.prevLine?.click()
		}),
		ArrowDown: prevDeflt(() => {
			if (document.activeElement === transciptOutput) {
				return clickLine()
			}
			const line = getHiglightedTranscriptLine()
			line?.nextLine?.click()
		}),
		pauseOnEdit: prevDeflt(() => {
			settingsStore.get().UI.pauseOnEdition && !player.paused && player.pause()
		}),
	},
}
const addKeyHandler = (/** @type {'keyup'|'keydown'}*/ evtType) => {
	document.body.addEventListener(evtType, (event) => {
		const shortCut = `${event.ctrlKey ? "Ctrl+" : ""}${event.key}`
		const handlers = keyBindingsCtrl[evtType]
		if (shortCut in handlers) {
			handlers[shortCut](event)
		} else if (
			evtType === "keyup" &&
			document.activeElement === transciptOutput &&
			!event.ctrlKey &&
			event.key.match(editKeysRegExp)
		) {
			keyBindingsCtrl.keyup.pauseOnEdit(event)
		}
	})
}
addKeyHandler("keydown")
addKeyHandler("keyup")
//#endregion keyboard shortcuts

// prevent pasting formatted text in transcript
transciptOutput.addEventListener('paste', (evt) => {
	evt.preventDefault()
	const data = evt.clipboardData?.getData('text/plain')
	const selection = window.getSelection();
	if (!data || !selection || !selection.rangeCount) {
		return
	}
	// execCommand is deprecated but it keeps hisotry (undo/redo) working
	// and there's no other known simple alternative for now
	if (document.execCommand) {
		document.execCommand('insertText', false, data);
		return
	} else {
		selection.deleteFromDocument();
		selection.getRangeAt(0).insertNode(document.createTextNode(data));
		selection.collapseToEnd();
	}
})

// play/pause on dblclick (but not if text selection)
transciptOutput.addEventListener("dblclick", (evt) => {
	const target = /** @type {HTMLElement|null} */(evt.target)
	if ( target?.nodeType !== Node.ELEMENT_NODE ){
		return
	}
	if (target.matches('transcript-line > .speaker, transcript-line > .time')){
		player.paused ? player.play() : player.pause()
	} else if (target.matches('transcript-line')) {
		const selectionString = window.getSelection()?.toString() || ''
		selectionString.match(/^\s*$/) && player.paused ? player.play() : player.pause()
	}
})