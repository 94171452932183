//@ts-check

import { settingsStore } from "./settings.js"
import { highlightTranscriptLine, newTranscriptLine } from "./transcriptEditor.js"
import { Whisper } from "./whisper.js"

const fixed = (/** @type {number} */ value) => value.toFixed(2)

const onEscape = (/** @type {KeyboardEvent} */ event) => {
	if (event.key == "Escape") {
		event.preventDefault()
		event.stopImmediatePropagation()
		editor.close()
	}
}
/**
 * @param {HTMLInputElement} input
 * @param {boolean} visible
 * @returns {HTMLInputElement} given input element
 */
const toggleInputDisplay = (input, visible = false) => {
	if (input.parentElement) {
		input.parentElement.style.display = visible ? "" : "none"
	}
	return input
}
//#region transcript timeline editor
const editor = {
	editor: /**@type {HTMLDialogElement} */ (document.getElementById("transcript-line-editor")),
	startInput: /**@type {HTMLInputElement} */ (document.querySelector("#transcript-line-editor [name=start]")),
	endInput: /**@type {HTMLInputElement} */ (document.querySelector("#transcript-line-editor [name=end]")),
	splitInput: /**@type {HTMLInputElement} */ (document.querySelector("#transcript-line-editor [name=split]")),
	msg: /**@type {HTMLElement} */ (document.querySelector("#transcript-line-editor .line-editor-message")),
	cancelBtn: /**@type {HTMLElement} */ (document.querySelector("#transcript-line-editor button.cancel")),
	validBtn: /**@type {HTMLElement} */ (document.querySelector("#transcript-line-editor button.validate")),
	onValidate: () => {},
	open: () => {
		editor.editor.showModal()
		window.addEventListener("keydown", onEscape, { capture: true })
	},
	close: () => {
		editor.editor.close()
		window.removeEventListener("keydown", onEscape, { capture: true })
		const { startInput, endInput, splitInput } = editor
		toggleInputDisplay(startInput, true).disabled = false
		toggleInputDisplay(endInput, true).disabled = false
		toggleInputDisplay(splitInput, false).disabled = false
	},
	/**
	 * @param {TREx.TranscriptLine} line
	 */
	editLine: (line) => {
		const { start, end, text, ts, prevLine } = line
		const { msg, startInput, endInput } = editor
		if (prevLine) {
			startInput.min = fixed(prevLine.start + 0.1)
		} else {
			startInput.min = "0.00"
		}
		startInput.max = fixed(end - 0.1)
		endInput.min = fixed(line.start + 0.1)
		endInput.max = ""
		msg.innerHTML = ts + "<br />" + (text.length < 50 ? text : text.slice(0, 23) + " ... " + text.slice(-23))
		startInput.value = fixed(start)
		endInput.value = fixed(end)
		editor.open()
		editor.onValidate = () => {
			const startValue = +startInput.value
			const endValue = +endInput.value
			const { start } = line // don't take end value now as it will probably change
			if (startValue != start) {
				// we must move line above accordingly
				const decalage = startValue - start
				line.prevLine?.setEnd(startValue)
				line.moveTime(decalage)
			}
			if (endValue != line.end) {
				// we must move line below accordingly
				line.addTime(endValue - line.end)
			}
		}
	},
	/** @param {TREx.TranscriptLine} line */
	insertBefore: (line) => {
		const { start, ts, prevLine } = line
		const { msg, startInput, endInput } = editor
		startInput.min = prevLine ? fixed(prevLine.start + 0.1) : "0.00"
		startInput.max = fixed(start - 0.1)
		endInput.min = fixed(+startInput.min + 0.1)
		endInput.max = ""
		msg.innerHTML = `Previous line: ${prevLine?.ts || "none"}<br />Next Line: ${ts}`
		startInput.value = prevLine ? fixed(prevLine.end) : "0.00"
		endInput.value = fixed(start + 0.1)
		editor.open()
		editor.onValidate = () => {
			const nStart = +startInput.value
			const nEnd = +endInput.value
			const newLine = newTranscriptLine({ start: nStart, end: nEnd })
			if (!newLine) {
				alert("Error while creating new line")
			}
			if (prevLine && nStart !== prevLine.end) {
				// we must move line above accordingly
				line.prevLine?.addTime(nStart - prevLine.end, { propagate: false })
			}
			if (nEnd != line.start) {
				// we must move line below accordingly
				line.moveTime(nEnd - line.start)
			}
			line.insertAdjacentElement("beforebegin", /** @type {HTMLElement} */ (newLine))
			// @ts-expect-error newLine is already tested
			highlightTranscriptLine(newLine, true)
		}
	},
	/** @param {TREx.TranscriptLine} line */
	deleteLine: (line) => {
		const { nextLine, prevLine } = line
		if (nextLine) {
			nextLine.setStart(line.start)
		}
		line.remove()
		const focusLine = nextLine || prevLine
		focusLine && highlightTranscriptLine(focusLine, true)
		editor.onValidate = () => {}
	},

	/**
	 * @param {TREx.TranscriptLine} line
	 */
	restartFromLine: (line) => {
		const mediaFileInputEl = /** @type {HTMLInputElement} */ (document.getElementById("file"))
		const mediaFile = mediaFileInputEl.files?.[0]
		if (!mediaFile) {
			alert("No media file selected can't start transcription without a media file")
			return
		}
		// remove all lines after this one
		const editor = /** @type {HTMLElement} */ (line.parentElement)
		const lines = Array.from(editor.children)
		const index = lines.indexOf(line)
		if (index > -1) {
			lines.slice(index).forEach((line) => line.remove())
		}
		Whisper.loadModel(settingsStore.get().whisper.model)
			.then(() => Whisper.loadMedia(mediaFile)) //, { startAt: line.start }))
			.then((audio) => {
				Whisper.process(audio, { translate: false, nbThreads: 4, startOffset: Math.round(line.start * 1000) })
			})
	},
	/** @param {TREx.TranscriptLine} line */
	insertAfter: (line) => {
		const { end, ts, nextLine } = line
		const { msg, startInput, endInput } = editor
		startInput.min = fixed(end)
		startInput.max = nextLine ? fixed(nextLine.end - 0.1) : ""
		startInput.disabled = true
		endInput.min = fixed(end + 0.1)
		endInput.max = nextLine ? fixed(nextLine.end - 0.1) : ""
		msg.innerHTML = `Previous line: ${ts || "none"}<br />Next Line: ${nextLine?.ts || "none"}`
		startInput.value = fixed(end)
		endInput.value = nextLine ? fixed(Math.min(end + 1, nextLine.end - 0.1)) : fixed(end + 1)
		editor.open()
		editor.onValidate = () => {
			const nStart = +startInput.value
			const nEnd = +endInput.value
			const newLine = newTranscriptLine({ start: nStart, end: nEnd })
			if (!newLine) {
				alert("Error while creating new line")
			}
			if (nextLine && nEnd !== line.end) {
				// we must move line after accordingly
				nextLine.moveTime(nEnd - line.end)
			}
			line.insertAdjacentElement("afterend", /** @type {HTMLElement} */ (newLine))
			// @ts-expect-error newLine is already tested
			highlightTranscriptLine(newLine, true)
		}
	},
	/**
	 * @param {TREx.TranscriptLine} line
	 * @param {number} currentTime
	 */
	splitLine: (line, currentTime = 0) => {
		const splitNode = window.getSelection()?.anchorNode || undefined
		const { start, end, text, ts } = line
		const { msg, startInput, endInput, splitInput } = editor
		toggleInputDisplay(startInput, false)
		toggleInputDisplay(endInput, false)
		toggleInputDisplay(splitInput, true)
		splitInput.min = fixed(start + 0.1)
		splitInput.max = fixed(end - 0.1)
		splitInput.value = fixed(Math.max(start + 0.1, Math.min(end - 0.1, currentTime)))
		msg.innerHTML = ts + "<br />" + (text.length < 50 ? text : text.slice(0, 23) + " ... " + text.slice(-23))
		editor.open()
		editor.onValidate = () => {
			const newLine = line.splitAt(+splitInput.value, splitNode)
			highlightTranscriptLine(newLine, true)
		}
	},
	/**
	 * @param {TREx.TranscriptLine} line
	 */
	mergeLine: (line) => {
		const { nextLine } = line
		if (!nextLine) {
			return
		}
		line.setEnd(nextLine.end)
		nextLine.remove()
		nextLine.querySelectorAll(".time, .speaker").forEach((el) => el.remove())
		;[...nextLine.childNodes].forEach((el) => line.appendChild(el))
	},
}

editor.cancelBtn.addEventListener("click", editor.close)
editor.validBtn.addEventListener("click", () => {
	editor.onValidate?.()
	editor.close()
})

editor.startInput.addEventListener("change", () => {
	const min = ((+editor.startInput.value * 100 + 10) / 100).toFixed(2)
	editor.endInput.min = min
	if (+editor.endInput.value <= +min) {
		editor.endInput.value = min
	}
})

editor.endInput.addEventListener("change", () => {
	const max = ((+editor.endInput.value * 100 - 10) / 100).toFixed(2)
	editor.startInput.max = max
	if (+editor.startInput.value >= +max) {
		editor.startInput.value = max
	}
})

export { editor as transcriptLineEditor }
//#endregion transcript timeline editor
