//@ts-check
//@ts-ignore
const rgb2hex = (c) => "#" + c.match(/\d+/g).map((x) => (+x).toString(16).padStart(2, 0)).join``

const stopPropagation = (/** @type {Event} */ evt) => {
	evt.stopImmediatePropagation()
}

class ColorDialog extends HTMLElement {
	#input
	#presetsContainer
	#reset
	#submit
	constructor() {
		super()
		const shadowRoot = this.attachShadow({ mode: "open" })
		shadowRoot.innerHTML = /*html*/ `
    <style>
      dialog{
        width: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        transition: transform 0.3s ease-in-out;
        border: solid black 1px;
        border-radius: .4rem;
        margin: 0;
				gap: .4em;
      }
      dialog[open]{
				display:flex;
      }
      dialog::backdrop{
        background-color:transparent;
      }
      :host(.backdrop) dialog::backdrop{
        background-color: rgba(0,0,0,0.6);
      }
      h3 {margin:0;}
			.presets {
				display: flex;
				flex-wrap: wrap;
				gap: .2rem;
			}
      .color-swatch{
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 2px;
        cursor: pointer;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px currentColor;
      }
      .color-swatch:hover{
				box-shadow: 0px 0px 3px 3px var(--accent-color, currentColor);
      }
      .color-swatch.selected {
        opacity.5;
        border-color: var(--accent-color);
				box-shadow: 0px 0px 3px 3px var(--accent-color, currentColor);
      }
			label {
				display: flex;
				align-items:center;
				cursor: pointer;
			}
			input{
				border:none;
				padding:0;
				height:1px;
				width:1px;
				visibility:hidden;
			}
			footer {
				display:flex;
				width:100%;
				justify-content: flex-end;
				gap: .4em;
			}
    </style>
    <dialog>
      <h3>Select a color</h3>
      <div class="presets"></div>
			<label><span class="color-swatch"></span><input type="color"/> Set custom color</label>
      <footer>
        <button class="reset"><i>eraser</i> reset</button>
				<button class="validate">validate</button>
      </footer>
    </dialog>`
		this.dialog = /**@type {HTMLDialogElement}*/ (shadowRoot.querySelector("dialog"))
		this.#presetsContainer = /**@type {HTMLElement}*/ (shadowRoot.querySelector(".presets"))
		this.#input = /**@type {HTMLInputElement}*/ (shadowRoot.querySelector("input[type=color]"))
		this.#reset = /**@type {HTMLElement}*/ (shadowRoot.querySelector("button.reset"))
		this.#submit = /**@type {HTMLElement}*/ (shadowRoot.querySelector("button.validate"))
		this.#input.addEventListener("change", (/** @type {any}*/ evt) => this.#onUserInterraction(evt))
		this.#presetsContainer.addEventListener("click", (/** @type {any}*/ evt) => this.#onUserInterraction(evt))
		this.#reset.addEventListener("click", () => {
			this.onClose("")
			this.close()
		})
		this.#submit?.addEventListener("click", () => {
			this.onClose(this.selected || "")
			this.close()
		})
		/** @type {string[]} */
		this.presets = []
		/** @type {undefined|(()=>void)} */
		this.onClose = () => {}
		/** @type {string} */
		this.selected = ""
	}
	#updateInputSwatch() {
		// @ts-expect-error we know it exists
		this.#input.previousElementSibling.style.backgroundColor = this.#input.value
	}
	#onUserInterraction(/**@type {Event &{target:HTMLElement}}*/ event) {
		const { type, target } = event
		if (type === "change" && target === this.#input) {
			this.selected = this.#input.value
			this.#updateInputSwatch()
		} else if (type === "click" && target.matches(".color-swatch")) {
			this.selected = rgb2hex(target.style.backgroundColor)
			this.#input.value = this.selected
			this.onClose(this.selected)
			this.close()
		}
		this.#renderPresets()
	}
	/**
	 * @param {HTMLElement|undefined} anchorElmt
	 */
	show(anchorElmt = undefined) {
		if (this.dialog.open) return
		this.#renderPresets()
		this.#input.value = this.selected
		const { style } = this.dialog
		if (anchorElmt) {
			const { top, left, height } = anchorElmt.getBoundingClientRect()
			style.top = top + height + 5 + "px"
			style.left = left + "px"
			// style.top = "calc(-50vh + " + top + "px)"
			// style.left = "calc(-50vw + " + left + "px)"
		}
		this.#updateInputSwatch()
		this.addEventListener("escape", stopPropagation, { once: true })
		this.dialog.showModal()
	}
	close() {
		this.removeEventListener("escape", stopPropagation)
		this.dialog.open && this.dialog.close()
		this.onClose = ColorDialog.prototype.onClose
	}
	#renderPresets() {
		this.#presetsContainer.innerHTML = this.presets
			.map(
				(color) =>
					`<span class="color-swatch${
						this.selected === color ? " selected" : ""
					}" style="background-color:${color};" tabIndex="0"></span>`
			)
			.join("")
	}
	/** @param {string} value*/
	//@ts-expect-error
	onClose(value) {}
}

const ensureDialog = () => {
	let dialog = document.querySelector("color-dialog")
	if (!dialog) {
		dialog = document.createElement("color-dialog")
		document.body.appendChild(dialog)
	}
	return /** @type {ColorDialog}*/ (dialog)
}

export class ColorPicker extends HTMLElement {
	static observedAttributes = ["value"]
	constructor() {
		super()
		this._internals = this.attachInternals()
	}
	connectedCallback() {
		this.addEventListener("click", () => {
			const presets = this.getAttribute("presets")?.split(/,|\|/)
			const dialog = ensureDialog()
			dialog.presets = presets || []
			dialog.selected = this.getAttribute("value") || ""
			dialog.classList.toggle("backdrop", this.hasAttribute("backdrop"))
			dialog.onClose = (/** @type {string} */ value) => {
				this.setAttribute("value", (this.value = value))
				this.dispatchEvent(new Event("change", { bubbles: true }))
			}
			dialog.show(this)
		})
	}
}

customElements.define("color-dialog", ColorDialog)
customElements.define("color-picker", ColorPicker)
