//@ts-check

import { app } from "./app.js"
import { mutate, setProp } from "./libs/tstorex/recipes.js"
import { ColorPicker } from "./customElements/ColorPicker.js"
import * as editor from "./transcriptEditor.js"
import { TranscriptLine } from "./customElements/TranscriptLine.js"

const btnAdd = /** @type {HTMLButtonElement}*/ (document.getElementById("speaker-add"))
const speakersContainer = /** @type {HTMLElement}*/ (document.getElementById("speakers"))
const statsWrapper = /** @type {HTMLDetailsElement}*/ (document.getElementById("speakers-stats"))
const statsContainer = /** @type {HTMLElement}*/ (statsWrapper.querySelector(".stat-container"))

const speakersStore = app.settingsStore.getScopeStore("speakers")

/**
 * @typedef Speaker
 * @property {string} id
 * @property {string} [name]
 * @property {string} [color]
 */

btnAdd?.addEventListener("click", () => {
	const lastId = Object.keys(speakersStore.get()).reduce((prev, cur) => Math.max(+prev, +cur), -1)
	/** @type {Speaker} */
	const speaker = { id: "" + (lastId + 1), name: "", color: "" }
	mutate(/** @type {any} */ (speakersStore), (state) => {
		state[speaker.id] = speaker
	})
})
// refresh stats on open
statsWrapper.addEventListener("toggle", () => {
	statsWrapper.open && refreshStats()
})
// refresh stats on dblclick
statsContainer?.addEventListener("dblclick", () => {
	console.log("refresh")
	refreshStats?.()
})

const colorPresets = [
	"#6f0000",
	"#881f00",
	"#ce5c36",
	"#f77967",
	"#ffa176",
	"#005c00",
	"#00995a",
	"#0056af",
	"#0087e7",
	"#6cb0f3",
	"#656bbe",
	"#b753d5",
	"#c44d8b",
	"#d44c61",
]

// track change in the speakers list
speakersStore.subscribe(
	(speakers) => {
		if (!Object.keys(speakers).length) {
			speakersContainer.innerHTML = ""
			statsWrapper.style.display = "none"
			return
		}
		statsWrapper.style.display = ""
		speakersContainer.innerHTML = /*html*/ `
			<div class="speaker row" data-speaker="null">
				<i 
					class="tag" 
					title="Set no speaker tag for the highlighted line (Ctrl+0)"
				>voice_over_off</i> no speaker
			</div>`
		const list = Object.values(speakers).map((/** @type {Speaker} */ speaker, index) => {
			const { id, name, color } = speaker
			const shortcuts = index < 9 ? ` (Ctrl+${index + 1})` : ""
			return /*html*/ `
			<div class="speaker row" data-speaker="${id}">
				<i 
					class="tag" 
					style="color:${color || "currentColor"};"
					title="Tag focused line with this speaker's name ${shortcuts}"
				>record_voice_over</i>
				<color-picker 
					value="${color}"
					backdrop
					presets="${colorPresets.join("|")}}"
					title="Choose a color for this speaker lines"
				>
					<i style="color:${color || "currentColor"};">water_drop</i>
				</color-picker>
				<input type="text" value="${name}" placeholder="speaker's name"/>
				<button
					class="speaker-delete"
					title="Delete this speaker"
				><i>person_remove</i></button>
			</div>`
		})
		speakersContainer.innerHTML += list.join("")
	},
	{ initCall: true }
)
speakersContainer.addEventListener("change", (evt) => {
	const target = /** @type {ColorPicker} */ (evt.target)
	if (target.matches("color-picker")) {
		const speakerId = /** @type {HTMLElement}*/ (target.closest(".speaker"))?.dataset?.speaker
		if (!speakerId) return
		const speakerStore = speakersStore.getScopeStore(speakerId)
		//@ts-expect-error
		setProp(speakerStore, "color", target.value)
	}
})

/** @param {Event} evt */
const delegatedClickHandlers = (evt) => {
	const target = /** @type {HTMLElement} */ (evt.target)
	const speakerId = /** @type {HTMLElement} */ (target.closest(".speaker"))?.dataset?.speaker
	if (!speakerId) return

	if (target.matches(".speaker-delete, .speaker-delete *")) {
		if (speakerId && confirm("Are you sure you want to remove this speaker?\nThis can't be undone!")) {
			TranscriptLine.bulkUpdate(() => {
				mutate(/** @type {any} */ (speakersStore), (state) => {
					delete state[speakerId]
				})
			}, true)
		}
	} else if (target.matches(".tag, .tag *")) {
		evt.preventDefault()
		/** @type {TREx.TranscriptLine|null} */
		const line = editor.container.querySelector("transcript-line.highlight")
		if (!line) return
		TranscriptLine.bulkUpdate(() => {
			if (line.dataset.speakerId === speakerId) {
				line.unbindSpeaker()
			} else {
				line.bindSpeaker(speakerId)
			}
		}, true)
	}
}

/** @param {Event} evt */
const delegatedChangeHandlers = (evt) => {
	const target = /** @type {HTMLInputElement} */ (evt.target)
	const speakerId = /** @type {HTMLInputElement} */ (target.closest(".speaker"))?.dataset?.speaker
	if (!speakerId) return
	const speakerStore = speakersStore.getScopeStore(speakerId)
	switch (target.type) {
		case "color":
			setProp(/** @type {any} */ (speakerStore), "color", target.value)
			break
		case "text":
			setProp(/** @type {any} */ (speakerStore), "name", target.value)
			break
	}
}

speakersContainer.addEventListener("change", delegatedChangeHandlers)
speakersContainer.addEventListener("mousedown", delegatedClickHandlers)

const calcSpeakerStats = () => {
	/** @typedef {{id:string, name?:string, color?:string, time:number, lines:number}} StatLine */
	const speakers = speakersStore.get()
	if (!speakers) {
		return []
	}
	const lines = TranscriptLine.getLines()
	const stats = Object.entries(speakers).reduce((acc, [id, speaker]) => {
		acc[id] = { ...speaker, time: 0, lines: 0 }
		return acc
	}, /** @type {Record<string, StatLine>} */ ({}))
	/** @type {string} */
	let prevSpeakerId = "null"
	for (const line of lines) {
		const lineSpeakerId = line.dataset.speakerId
		/** @type {StatLine | undefined} */
		let statLine = undefined
		if (lineSpeakerId) {
			prevSpeakerId = lineSpeakerId
			lineSpeakerId !== "null" && (statLine = stats[lineSpeakerId])
		} else if (prevSpeakerId !== "null") {
			statLine = stats[prevSpeakerId]
		}
		if (statLine) {
			statLine.lines++
			statLine.time += line.end - line.start
		}
	}
	return Object.values(stats).map((stat) => ({ ...stat, time: stat.time * 1000 }))
}

const refreshStats = () => {
	const stats = calcSpeakerStats()
	if (!stats.length) {
		statsContainer.innerHTML = ""
		return
	}
	statsContainer.innerHTML = stats
		.map(
			({ name, color, lines, time }) => /** html */ `
	<div class="row gap sb" style="color:${color || "currentColor"}">
		<strong>${name || "unknown"}</strong>
		<div>
			Time: ${new Date(time).toISOString().slice(11, 19)}
			<br />
			Lines: ${lines}
		</div>
	</div>`
		)
		.join("")
}
export { speakersContainer, calcSpeakerStats }
