//@ts-check
import { goToScreenLast, goToScreenNext } from "./screenNavitation.js"
import { settingsStore } from "./settings.js"

const logsArea = /** @type {HTMLInputElement} */ document.getElementById("output")

//@ts-check
export const app = {
	settingsStore,
	nextScreen: goToScreenNext,
	lastScreen: goToScreenLast,
	isWorking: () => document.body.classList.contains("loading"),
	setWorkingState: (/** @type {boolean} */ isWorking) => {
		document.body.classList.toggle("loading", isWorking)
	},
	/** @param {string[]} args */
	log: (...args) => app.logLevel("info", ...args),
	/** @param {string[]} args */
	logError: (...args) => app.logLevel("error", ...args),
	/** @param {string[]} args */
	logWarn: (...args) => app.logLevel("warn", ...args),
	/**
	 * @param {'info'|'warn'|'error'} level
	 * @param {string[]} args
	 */
	logLevel(level, ...args) {
		if (!logsArea) {
			console[level](...args)
		} else {
			args.forEach((arg) => {
				const log = logsArea.appendChild(document.createElement("div"))
				log.innerText = arg
				log.classList.add(level)
			})
			logsArea.scrollTop = logsArea.scrollHeight
		}
	},
}
