const getPColor = (p) => {
	const r = Math.round(Math.min(255, Math.max(0, 255 - p * 2.55)))
		.toString(16)
		.padStart(2, "0")
	const g = Math.round(Math.min(255, Math.max(0, p * 2.55)))
		.toString(16)
		.padStart(2, "0")
	return `#${r}${g}00`
}
if (!document.head.querySelector("style#tprob")) {
	const styleEl = document.createElement("style")
	styleEl.id = "tprob"
	styleEl.innerHTML = /*css*/ `
  .display-prob t-prob { border-bottom: 4px solid var(--p-color);}
`
	document.head.appendChild(styleEl)
}
export class TProb extends HTMLElement {
	connectedCallback() {
		this.style.setProperty("--p-color", getPColor(this.getAttribute("p")))
	}
}

customElements.define("t-prob", TProb)
