//@ts-check
import { settingsStore } from "./settings.js"
import { bindInput, setProp } from "./libs/tstorex/recipes.js"
import {
	copySafe,
	highlightTranscriptLine,
	highlightTranscriptLineAt,
	loadTranscript,
	onTranscriptLineClick,
	saveTranscript,
	toggleConfidenceDisplay,
	toggleLineNumbers,
	toggleSpeakersDisplay,
	toggleTsFull,
} from "./transcriptEditor.js"
import { transcriptLineEditor } from "./transcriptLineEditor.js"
import { player, setMedia } from "./player.js"
import { Whisper } from "./whisper.js"
import { app } from "./app.js"

// must keep this import it makes side effects we want
import "./speakers.js"

//#region settings

// /** @type {AudioContext|null} web audio context*/
// let context = null

/** @type {Float32Array|null} audio data*/
let audio = null

const { name: dbName, version: dbVersion } = settingsStore.get().db
// @ts-expect-error
window.dbName = dbName
// @ts-expect-error
window.dbVersion = dbVersion
//#endregion settings

//#region get UIElements
const inputloadTranscript = /** @type {HTMLInputElement} */ (document.getElementById("load-transcript"))
const inputThread = /** @type {HTMLInputElement} */ (document.getElementById("threads-input"))
const threadValue = /** @type {HTMLElement} */ (document.getElementById("threads-value"))
const btnCopy = /** @type {HTMLElement} */ (document.getElementById("copy"))
const btnSave = /** @type {HTMLElement} */ (document.getElementById("save"))
const toggleInputLineNumbers = /** @type {HTMLElement} */ (document.getElementById("toggle-line-numbers"))
const toggleInputTsFull = /** @type {HTMLElement} */ (document.getElementById("toggle-ts-full"))
const toggleSpeakers = /** @type {HTMLElement} */ (document.getElementById("toggle-speakers"))
const toggleConfidence = /** @type {HTMLElement} */ (document.getElementById("toggle-condidence"))
const togglePauseOnEdit = /** @type {HTMLElement} */ (document.getElementById("toggle-pause-on-edit"))
const tooltipButton = document.querySelectorAll(".tooltip-button")
const languageSelectEl = /** @type {HTMLSelectElement} */ (document.getElementById("language"))
const mediaFileInputEl = /** @type {HTMLInputElement} */ (document.getElementById("file"))
const btnStartTranscript = /** @type {HTMLButtonElement} */ (document.getElementById("start-transcript"))
const btnStartTranslate = /** @type {HTMLButtonElement} */ (document.getElementById("start-translate"))
const ctxMenuTranscript = /** @type {TREx.ContextMenuElement} */ (document.getElementById("transcript-context-menu"))
//#endregion get UIElements

// ensure file input values are reseted
window.addEventListener("load", () => {
	// @ts-expect-error value exsists on inputs
	document.querySelectorAll("input[type=file]").forEach((el) => (el.value = ""))
})

/** @param {boolean} loading */
inputloadTranscript.addEventListener("change", () => {
	loadTranscript(inputloadTranscript.files?.[0]).then(() => app.nextScreen())
})

// --- select default lang ---
const browserLang = navigator.language.replace(/-.*$/, "")
setProp(
	/**@type {any}*/ (settingsStore),
	"audio.lang",
	languageSelectEl.querySelector(`option[value="${browserLang}"]`) ? browserLang : "en"
)
bindInput(settingsStore.getScopeStore("audio.lang"), languageSelectEl)

// --- select audio file ---
mediaFileInputEl.addEventListener("change", async () => {
	const mediaFile = mediaFileInputEl.files?.[0]
	if (!mediaFile) {
		return
	}
	mediaFileInputEl.disabled = true
	app.setWorkingState(true)
	const saveFileName = settingsStore.get().save.fileName
	setMedia(mediaFile)
	// if (mediaFile.type.startsWith("video/")) {
	// 	document.body.style.setProperty("--headerHeight", "200px")
	// }
	app.setWorkingState(false)
	if (saveFileName) {
		app.lastScreen()
	} else {
		// only do the extra work if we need to pass audio to the WASM module
		audio = await Whisper.loadMedia(mediaFile)
		//@ts-expect-error don't know how to fix this for now
		setProp(settingsStore, "save.fileName", `${mediaFile.name.replace(/\.[^.]*$/, ".")}transcript.json`)
		app.nextScreen()
	}
})

//#region toolbox
btnSave.addEventListener("click", saveTranscript)
btnCopy.addEventListener("click", copySafe)

player.addEventListener("timeupdate", () => {
	highlightTranscriptLineAt(player.currentTime)
})
onTranscriptLineClick((line) => {
	const { start, end } = line
	if (line.childNodes.length === 0) {
		line.appendChild(document.createTextNode(""))
	}
	highlightTranscriptLine(line, true)
	if (player && (start >= player.currentTime || end < player.currentTime)) {
		player.currentTime = start
	}
})

// handle line numbers display
const lineNbStore = settingsStore.getScopeStore("UI.displayLineNumbers")
bindInput(lineNbStore, toggleInputLineNumbers)
lineNbStore.subscribe(toggleLineNumbers, { initCall: true })
// handle timeStamps display
const tsFullStore = settingsStore.getScopeStore("UI.displayFullTs")
bindInput(tsFullStore, toggleInputTsFull)
tsFullStore.subscribe(toggleTsFull, { initCall: true })
// handle speakers display
const speakersDisplayStore = settingsStore.getScopeStore("UI.displaySpeakers")
bindInput(speakersDisplayStore, toggleSpeakers)
speakersDisplayStore.subscribe(toggleSpeakersDisplay, { initCall: true })
// handle confidence display
const condidenceDisplayStore = settingsStore.getScopeStore("UI.displayConfidence")
bindInput(condidenceDisplayStore, toggleConfidence)
condidenceDisplayStore.subscribe(toggleConfidenceDisplay, { initCall: true })
// handle pause on edition
const pauseOnEditStore = settingsStore.getScopeStore("UI.pauseOnEdition")
bindInput(pauseOnEditStore, togglePauseOnEdit)

//#endregion toolbox

// transcribe
inputThread.max = "" + navigator.hardwareConcurrency
const threadStore = settingsStore.getScopeStore("whisper.threads")
bindInput(threadStore, inputThread, {
	valueAccessor: "valueAsNumber",
	inputToStore: (value) => {
		return value ? +value : 0
	},
})
threadStore.subscribe((value) => (threadValue.innerHTML = "" + value), { initCall: true })

btnStartTranslate.addEventListener(
	"click",
	() => {
		audio && Whisper.process(audio, { translate: true })
		btnStartTranslate.disabled = true
	},
	{ once: true }
)
btnStartTranscript.addEventListener(
	"click",
	() => {
		audio && Whisper.process(audio, { translate: false })
		btnStartTranscript.disabled = true
	},
	{ once: true }
)

//#region tooltips
tooltipButton.forEach((el) => {
	const tooltip = /** @type {HTMLElement|null} */ (el.nextElementSibling)
	if (!(tooltip && tooltip.classList.contains("tooltip"))) {
		return
	}
	const hide = () => {
		tooltip.classList.remove("opened")
	}
	const hideStop = (/** @type {Event} */ evt) => {
		evt.stopImmediatePropagation()
		evt.preventDefault()
		hide()
	}
	document.body.appendChild(tooltip)
	tooltip.tabIndex = -1
	el.addEventListener("click", () => {
		tooltip.classList.toggle("opened")
		if (tooltip.classList.contains("opened")) {
			tooltip.focus()
			tooltip.addEventListener("blur", hide, { once: true })
			tooltip.addEventListener("keydown", hideStop)
		} else {
			tooltip.removeEventListener("blur", hide)
			tooltip.removeEventListener("keydown", hideStop)
		}
	})
})
//#endregion tooltips

//#region context menu
let restarted = false
ctxMenuTranscript.addOpenHandler((/**@type {Event}*/ _evt, /** @type {?TREx.TranscriptLine} */ line) => {
	if (!line) {
		return
	}
	line.click()
	// toggle menuEntries visibility
	const canRestart = restarted ? false : !!inputloadTranscript.files?.[0]
	ctxMenuTranscript.querySelector("[data-action=restart]")?.toggleAttribute("disabled", !canRestart)
	// toggle merge
	ctxMenuTranscript.querySelector("[data-action=merge]")?.toggleAttribute("disabled", !line.nextLine)
})
ctxMenuTranscript.addEventListener("click", (evt) => {
	const {
		target: {
			//@ts-expect-error
			dataset: { action },
		},
	} = evt
	const line = /** @type {TREx.TranscriptLine} */ (ctxMenuTranscript.openerElement)
	switch (action) {
		case "edit":
			transcriptLineEditor.editLine(line)
			break
		case "split":
			transcriptLineEditor.splitLine(line, player.currentTime)
			break
		case "merge":
			transcriptLineEditor.mergeLine(line)
			break
		case "insertBefore":
			transcriptLineEditor.insertBefore(line)
			break
		case "insertAfter":
			transcriptLineEditor.insertAfter(line)
			break
		case "delete":
			setTimeout(() => {
				if (confirm("Are you sure you want to delete this line ?\nThis can't be undone.")) {
					transcriptLineEditor.deleteLine(line)
				}
			})
			break
		case "restart":
			setTimeout(() => {
				if (
					confirm(
						"Are you sure you want to restart the transcript from here ?\nSubsequent lines will be deleted.\nThis can't be undone."
					)
				) {
					transcriptLineEditor.restartFromLine(line)
					restarted = true
				}
			})
			break
		case "copy":
			copySafe()
			break
		case "save":
			setTimeout(saveTranscript)
			break
	}
})
//#endregion context menu

// load wasm module
if (!settingsStore.get().save.fileName) {
	const transcriptScript = document.createElement("script")
	transcriptScript.src = "./transcript.js"
	document.body.appendChild(transcriptScript)
}
app.setWorkingState(false)
