//@ts-check
/**
 * @typedef {(evt:Event, openerElement?:HTMLElement)=>void|false} OpenHandler
 */

//@fixme(contextMenu) Add some magic for placement of the context menu
class ContextMenu extends HTMLElement {
	/** @type {OpenHandler[]} */
	#openHandlers = []
	/** @type {HTMLElement | null} */
	#openerElement
	/** @type {Element | null} */
	#previouslyActiveElement
	constructor() {
		super()
		this.attachShadow({ mode: "open" })
		// @ts-expect-error
		this.shadowRoot.innerHTML = /*html*/ `<style>
        :host {
          display: none;
          position: fixed;
          top:0;
          left:0;
          background-color: #333;
          color: #eee;
        }
        :host([open]) {
          z-index: 100;
					display: block;
        }
        :host([open]) #menu {
          display: block;
        }
        #menu {
          display: none;
          position: absolute;
          flex-direction: column;
          top: 0;
          left: 0;
          background-color: inherit;
          color: inherit;
          border: 1px solid #000;
          border-radius: .2rem;
          padding: .4rem;
          box-shadow: 0 0 1rem rgba(0,0,0,.5);
					outline: none;
        }
        ::slotted(*:hover) {
          background-color: #000;
					color:#fff;
        }
				@media (prefers-color-scheme: light) {
					:host {
						background-color: #eee;
						color: #333;
					}
					::slotted(*:hover) {
						background-color: #aaa;
						color: #000;
					}
				}
      </style><div id="menu" tabIndex="-1"><slot></slot></div>`
		this.#openerElement = null
		this.#openHandlers = []
		this.#previouslyActiveElement = null
		this.menu = /** @type {HTMLElement}*/ (this.shadowRoot?.querySelector("#menu"))
		this.handlers = {
			click: (/**@type {any}*/ evt) => {
				this.hasAttribute("open") && this.handlers.close()
				if (evt.shiftKey || evt.ctrlKey || evt.altKey || evt.metaKey) {
					return
				}
				const selector = this.getAttribute("for")
				const target = evt.target.closest(selector)
				if (target && target.matches(selector)) {
					this.#openerElement = target
					evt.preventDefault()
					evt.stopPropagation()
					if (this.#openHandlers?.length) {
						if (this.#openHandlers.some((handler) => handler(evt, this.#openerElement || undefined))) {
							return
						}
					}
					this.#previouslyActiveElement = document.activeElement
					this.toggleAttribute("open")
					this.menu.focus()
					this.menu.style.left = evt.clientX + "px"
					this.menu.style.top = evt.clientY + "px"
					const rect = this.menu.getBoundingClientRect()
					if (rect.bottom > window.innerHeight) {
						this.menu.style.top = evt.clientY - rect.height + "px"
					}
					window.addEventListener("click", this.handlers.close, { once: true })
					window.addEventListener("keydown", this.handlers.onEscape, { capture: true })
				}
			},
			/**
			 * @param {KeyboardEvent} evt
			 */
			onEscape: (evt) => {
				if (evt.key === "Escape") {
					evt.stopImmediatePropagation()
					evt.preventDefault()
					this.handlers.close()
				}
			},
			close: () => {
				this.removeAttribute("open")
				this.#openerElement = null
				window.removeEventListener("click", this.handlers.close)
				window.removeEventListener("keydown", this.handlers.onEscape, { capture: true })
				// @ts-expect-error
				this.#previouslyActiveElement?.focus?.()
			},
		}
	}

	/**
	 * @param {OpenHandler} handler
	 * @returns {()=>void} removeHandler function
	 */
	addOpenHandler(handler) {
		this.#openHandlers.push(handler)
		return () => this.removeOpenHandler(handler)
	}
	/**
	 * @param {OpenHandler} handler
	 */
	removeOpenHandler(handler) {
		const index = this.#openHandlers.indexOf(handler)
		if (index >= 0) {
			this.#openHandlers.splice(index, 1)
		}
	}

	get openerElement() {
		return this.#openerElement
	}

	connectedCallback() {
		document.addEventListener("contextmenu", this.handlers.click)
	}
	disconnectedCallback() {
		document.removeEventListener("contextmenu", this.handlers.click)
	}
}

customElements.define("context-menu", ContextMenu)
