// @ts-check
import { settingsStore } from "./settings.js"
const introEl = /** @type {HTMLElement} */ (document.getElementById("intro"))
const btnNointro = /** @type {HTMLElement} */ (document.getElementById("nointro"))
const screens = /** @type {HTMLElement[]} */ (Array.from(document.querySelectorAll("article > section")))

export const goToScreen = (/** @type {number} */ screen) => {
	screens.forEach((el, key) => {
		el.style.display = key === screen ? "flex" : "none"
	})
}
export const goToScreenNext = () => {
	const activeScreen = screens.findIndex((el) => el.style.display !== "none")
	if (screens.length - 1 > activeScreen) {
		goToScreen(activeScreen + 1)
	}
}
export const goToScreenLast = () => goToScreen(screens.length - 1)

const removeIntros = () => {
	document.querySelectorAll("#intro").forEach((el) => {
		el.remove()
	})
}
const displayIntro = settingsStore.getScopeStore("UI.displayIntro")
if (displayIntro.get()) {
	introEl.style.display = "flex"
	const disableIntro = () => {
		displayIntro.set(false)
		removeIntros()
	}
	btnNointro.addEventListener("click", disableIntro, { once: true })
	// copy intro to other screens
	screens.slice(1).forEach((el) => {
		if (!el.classList.contains("no-intro")) {
			el.insertAdjacentElement("afterbegin", /** @type {HTMLElement} */ (introEl.cloneNode(true)))
			el.querySelector("#nointro")?.addEventListener("click", disableIntro, { once: true })
		}
	})
} else {
	removeIntros()
}
//@ts-expect-error
;(window.transcriptex || (window.transcriptex = {})).goToScreen = goToScreen
