//@ts-check
/** @type {Record<string, {label: string, size: number}>} */
export const models = {
	"tiny.en": {
		label: "tiny.en: English only, fastest but don't expect accuracy (75 MB of RAM required)",
		size: 75,
	},
	tiny: {
		label: "tiny: international, fastest but don't expect accuracy (75 MB of RAM required)",
		size: 75,
	},
	"tiny-en-q5_1": {
		label: "tiny.en quantized: English only, blazing fast but don't expect accuracy (31 MB of RAM required)",
		size: 31,
	},
	"tiny-q5_1": {
		label: "tiny quantized: international, blazing fast but don't expect accuracy (31 MB of RAM required)",
		size: 31,
	},
	base: {
		label: "base: international, fast with poor accuracy (142 MB of RAM required)",
		size: 142,
	},
	"base-q5_1": {
		label: "base quantized: international, fast with poor accuracy (57 MB of RAM required)",
		size: 57,
	},
	small: {
		label: "small: international, medium speed with good accuracy (466 MB of RAM required)",
		size: 466,
	},
	"small-q5_1": {
		label: "small quantized: international, medium speed with good accuracy (182 MB of RAM required)",
		size: 182,
	},
	"medium-q5_0": {
		label: "medium quantized: international, slow with pretty good accuracy (515 MB of RAM required)",
		size: 515,
	},
}
