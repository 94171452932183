//@ts-check
import { createStore } from "./libs/tstorex/index.js"
import { bindStorage } from "./libs/tstorex/recipes.js"

//@ts-expect-error
window.transcriptex = window.transcriptex || {}
/** @type {TREx.Transcriptex["settings"]} */
const settings = {
	whisper: {
		model: "",
		threads: Math.floor(Math.min(Math.max(1, navigator.hardwareConcurrency / 2), 8)),
	},
	audio: {
		fileName: "",
		lang: "en",
	},
	db: {
		version: 1,
		name: "whisper.ggerganov.com",
	},
	UI: {
		displayIntro: true,
		displayLineNumbers: false,
		displayFullTs: false,
		displaySpeakers: false,
		displayConfidence: false,
		pauseOnEdition: false,
	},
	save: {
		formatVersion: 3,
		fileName: "",
	},
	speakers: {},
}
export const settingsStore = createStore(settings)
const storage = localStorage
bindStorage(settingsStore.getScopeStore("UI"), { key: "UI", storage })

settingsStore.subscribe((state) => {
	//@ts-expect-error
	window.transcriptex.settings = state
})
