//@ts-check
import "./TProb.js"
import { settingsStore } from "../settings.js"
const speakersStore = settingsStore.getScopeStore("speakers")

const parseTimeData = (/** @type {string|undefined} */ time) => {
	if (!time) return [null, null]
	const m = time.match(/^([0-9:.-]+)\s+-->\s+([0-9:.-]+)$/)
	return m ? [m[1], m[2]].map((t) => new Date(`1970-01-01T${t}Z`).getTime() / 1000) : [null, null]
}
const noNbsp = (/** @type {string} */ text) => (text ? text.replace(/&nbsp;| /g, " ") : "")
const spaceCollapse = (/** @type {string} */ text) => (text ? text.replace(/ {2,}}/g, " ") : "")

/** @param {string} hex */
const hextodec = (hex) => parseInt(hex.length === 1 ? hex.repeat(2) : hex, 16)
/** @param {string} color */
const safeForeground = (color) => {
	if (color[0] !== "#") {
		throw new Error("color must be a hex string")
	}
	color = color.slice(1)
	/** @type {[number, number, number]} */
	let RGB = [0, 0, 0]
	if (color.length === 3) {
		//@ts-expect-error it is 3 characters long
		RGB = [...[...color].map(hextodec), 1]
	} else if (color.length === 6) {
		//@ts-expect-error it can't be null
		RGB = color.match(/.{2}/g).map(hextodec)
	}
	return Math.round((RGB[0] * 299 + RGB[1] * 587 + RGB[2] * 114) / 1000) > 127 ? "#000000" : "#ffffff"
}
const getCaretNode = () => window.getSelection()?.focusNode || null
const getCaretElement = () => {
	let focusNode = getCaretNode()
	if (focusNode && focusNode.nodeType !== Node.ELEMENT_NODE) {
		focusNode = focusNode.parentElement
	}
	return /**@type {HTMLElement|null} */ (focusNode)
}
if (!document.head.querySelector("style#transcript-line")) {
	const styleEl = document.createElement("style")
	styleEl.id = "transcript-line"
	styleEl.innerHTML = /*css*/ `
	transcript-line {
		display: block;
		transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
		width: fit-content;
		min-width: 100%;
		line-height: 1.2em;
		color: var(--fg, currentColor);
	}
	transcript-line.highlight {
		background-color: var(--highlight-bg-color, var(--flame));
		color: var(--highlight-fg-color, #fff);
	}
	transcript-line > .time {
		font-family: "Inconsolata", monospace;
		font-size: 0.8em;
		margin-right: 0.4em;
		user-select: none;
	}
	transcript-line > .speaker {
		display:none;
		font-style: italic;
		font-weight: thin;
		user-select: none;
	}
	.display-speakers transcript-line > .speaker{
		display: inline;
		color: currentColor;
	}
`
	document.head.appendChild(styleEl)
}

/** @implements {TREx.TranscriptLine} */
export class TranscriptLine extends HTMLElement {
	static observedAttributes = ["data-ts", "data-speaker-name", "data-speaker-color"]
	static exportFontFamily = "cursive"
	static exportFontSize = "12pt"
	static exportMetaFontFamily = "monospace"
	static exportMetaFontSize = "8pt"
	static #bulkUpdate = false
	/** @type {number} */
	#start
	/** @type {number} */
	#end
	/** @type {HTMLElement} */
	#timeElmt
	/** @type {HTMLElement} */
	#speakerElmt
	/** @type {(()=>void)|undefined} */
	#unbindSpeaker
	/** @type {undefined | TREx.Store<TREx.Speaker>} */
	#speaker

	constructor() {
		super()
		this.#start = 0
		this.#end = 0
		this.#speakerElmt = document.createElement("span")
		this.#speakerElmt.classList.add("speaker")
		this.#speakerElmt.contentEditable = "false"
		this.insertAdjacentElement("afterbegin", this.#speakerElmt)
		this.#timeElmt = document.createElement("span")
		this.#timeElmt.classList.add("time")
		this.#timeElmt.contentEditable = "false"
		this.insertAdjacentElement("afterbegin", this.#timeElmt)
	}

	/**
	 * @returns {TranscriptLine[]}
	 */
	static getLines(selector = undefined) {
		return Array.from(document.querySelectorAll(`transcript-line${selector || ""}`))
	}

	/**
	 * @returns {TranscriptLine|null}
	 */
	static getCaretLine() {
		let focusElmt = getCaretElement()
		// @ts-ignore
		return focusElmt?.closest("transcript-line") || null
	}
	static getCaretProb() {
		let focusElmt = getCaretElement()
		// @ts-ignore
		return focusElmt?.closest("t-prob") || null
	}

	/** @type {(fn: () => void, render?: boolean) => void} */
	static bulkUpdate(fn, render = true) {
		TranscriptLine.#bulkUpdate = true
		try {
			fn()
		} finally {
			render && this.getLines().forEach((line) => /** @type {TranscriptLine}*/ (line).render())
			TranscriptLine.#bulkUpdate = false
		}
	}

	bindSpeaker(/** @type {string} */ speakerId) {
		if (!speakerId || speakerId === "null") {
			this.#unbindSpeaker?.()
			speakerId === "null" && (this.dataset.speakerId = "null")
			return
		}
		if (this.#unbindSpeaker) {
			if (this.dataset.speakerId === speakerId) {
				return
			}
			this.#unbindSpeaker()
		}
		this.#speaker = speakersStore.getScopeStore(speakerId)
		const cleanDataset = () => {
			if (this.dataset.speakerId !== "null") {
				this.removeAttribute("data-speaker-id")
			}
			this.removeAttribute("data-speaker-name")
			this.removeAttribute("data-speaker-color")
		}
		const unsubscribe = this.#speaker.subscribe(
			/**@param {TREx.Speaker} speaker */
			(speaker) => {
				if (speaker === undefined) {
					this.unbindSpeaker?.()
					return
				}
				const { id, name, color } = speaker
				this.dataset.speakerId = id
				this.dataset.speakerName = name
				this.dataset.speakerColor = color
				TranscriptLine.#bulkUpdate || this.render()
			},
			{
				initCall: true,
				// equalityCheck: (a, b) => {
				// 	console.log("a = b", a, b, a.name === b.name && a.color === b.color && a.id === b.id)
				// 	return a.name === b.name && a.color === b.color && a.id === b.id
				// },
			}
		)
		this.#unbindSpeaker = () => {
			this.#speaker = undefined
			unsubscribe()
			this.#unbindSpeaker = undefined
			cleanDataset()
			TranscriptLine.#bulkUpdate || this.render()
		}
		return unsubscribe
	}
	unbindSpeaker() {
		this.#unbindSpeaker?.()
		this.dataset.speakerId === "null" && this.removeAttribute("data-speaker-id")
	}
	connectedCallback() {
		this.#onTsUpdate()
		if (this.dataset.speakerId && this.dataset.speakerId !== "null") {
			this.bindSpeaker(this.dataset.speakerId)
		}
	}
	disconnectedCallback() {
		this.#unbindSpeaker?.()
	}
	/**
	 * @param {string} name
	 * @param {string|null} oldValue
	 * @param {string|null} newValue
	 */
	// @ts-expect-error unused params
	attributeChangedCallback(name, oldValue, newValue) {
		if (name === "data-ts") {
			this.#onTsUpdate()
		} else if (name === "data-speaker-id") {
			if (!newValue || newValue === "null") {
				this.#unbindSpeaker?.()
			} else {
				this.bindSpeaker(newValue)
			}
			// TranscriptLine.#bulkUpdate || this.render()
		}
	}
	#onTsUpdate() {
		const [start, end] = parseTimeData(this.dataset.ts)
		this.#start = start || 0
		this.#end = end || 0
		TranscriptLine.#bulkUpdate || this.render()
	}
	#updateTs() {
		this.dataset.ts = `${new Date(this.#start * 1000).toISOString().slice(11, 23)} --> ${new Date(this.#end * 1000)
			.toISOString()
			.slice(11, 23)}`
	}
	get start() {
		return this.#start
	}
	get end() {
		return this.#end
	}
	get text() {
		return this.childNodes[0]?.textContent || ""
	}
	get ts() {
		return this.dataset.ts
	}
	/**
	 * @returns {{id?:string, name?:string, color?:string}}}
	 */
	get speakerData() {
		if (!this.#speaker && !this.dataset.speakerId) {
			return this.prevLine?.speakerData || {}
		}
		return this.#speaker?.get() || {}
	}

	/** @returns {TranscriptLine|null} */
	get prevLine() {
		return this.previousElementSibling instanceof TranscriptLine ? this.previousElementSibling : null
	}
	/** @type {TranscriptLine|null} */
	get nextLine() {
		return this.nextElementSibling instanceof TranscriptLine ? this.nextElementSibling : null
	}

	grabFocus() {
		const { activeElement } = document
		if (activeElement === this) {
			return
		} else if (activeElement === this.parentElement) {
			const caretLine = TranscriptLine.getCaretLine()
			if (caretLine === this) {
				return
			}
		}
		if (this.childNodes.length < 1) {
			const textNode = document.createTextNode("...")
			this.appendChild(textNode)
		}
		const selection = document.getSelection()
		// selection?.removeAllRanges()
		const range = document.createRange()
		range.selectNode(this.childNodes[0])
		range.collapse(true)
		selection?.addRange(range)
		// this.focus()
	}

	// add some duration to the line
	/** @type {(time: number, options?: { propagate?: boolean }) => void} */
	addTime(seconds, options) {
		this.#end += seconds
		this.#updateTs()
		const { propagate = true } = options || {}
		// get next sibling and move it accordingly in time
		propagate && this.nextLine?.moveTime(seconds)
	}
	// move the line forward or backward in time
	moveTime(/** @type {number} */ seconds) {
		this.#start += seconds
		this.#end += seconds
		this.#updateTs()
		// get next sibling and move it accordingly in time
		this.nextLine?.moveTime(seconds)
	}
	/**
	 * split the given line at the given time
	 * @param {number} seconds it not between start and end will split in the middle
	 * @param {Node|undefined} caretNode if not given will split at caret position if any
	 * @returns {TranscriptLine} the new line
	 */
	splitAt(seconds, caretNode = undefined) {
		const newLine = new TranscriptLine()
		Object.entries(this.dataset).forEach(([key, value]) => {
			newLine.dataset[key] = value
		})
		// find the split node
		let splitElmt = this.children[2] // first t-prob
		if (caretNode && this.contains(caretNode)) {
			if (caretNode.nodeType !== Node.ELEMENT_NODE) {
				const prob = caretNode.parentElement?.closest("t-prob")
				prob && (splitElmt = prob)
			}
		} else {
			const caretProb = TranscriptLine.getCaretProb()
			caretProb && this.contains(caretProb) && (splitElmt = caretProb)
		}
		const children = Array.from(this.children)
		const splitIndex = children.indexOf(splitElmt)
		const splittedChildren = children.slice(children.length > splitIndex + 1 ? splitIndex + 1 : splitIndex)
		splittedChildren.forEach((child) => {
			newLine.appendChild(child)
		})
		// if seconds is not in the line we split in the middle
		if (!seconds || seconds < this.start || seconds > this.end) {
			seconds = this.start + (this.end - this.start) / 2
		}
		this.setEnd(seconds)
		newLine.setStart(seconds)
		this.insertAdjacentElement("afterend", newLine)
		return newLine
	}

	setStart(/** @type {number} */ seconds) {
		this.#start = seconds
		this.#updateTs()
	}
	setEnd(/** @type {number} */ seconds) {
		this.#end = seconds
		this.#updateTs()
	}

	render() {
		//@ts-ignore
		// console.log("render", this.dataset.ts, this.#speaker?.get())
		const ts = this.dataset.ts
		const { name, color } = this.speakerData
		if (color) {
			this.style.setProperty("--fg", color)
			this.style.setProperty("--highlight-bg-color", color)
			this.style.setProperty("--highlight-fg-color", safeForeground(color))
		} else {
			this.style.removeProperty("--fg")
			this.style.removeProperty("--highlight-bg-color")
			this.style.removeProperty("--highlight-fg-color")
		}
		this.#speakerElmt.innerText = name ? `${name}: ` : ""
		this.#timeElmt.title = ts || "??:??:??.??? --> ??:??:??.???"
		if (this.matches(".ts-start transcript-line")) {
			this.#timeElmt.innerText = ts?.slice(0, 8) || "??:??:??"
		} else if (this.matches(".ts-full transcript-line")) {
			this.#timeElmt.innerText = ts?.replace(/\.\d\d\d/g, "") || "??:??:?? --> ??:??:??"
		} else {
			this.#timeElmt.innerText = ts?.slice(0, 8) || "??:??:??"
		}
		// propagate speakers to all untag lines
		if (!TranscriptLine.#bulkUpdate && this.dataset.speakerId) {
			/** @type {TREx.TranscriptLine[]} */
			let lines = this.parentElement ? Array.from(this.parentElement.querySelectorAll("transcript-line")) : []
			const findIndex = lines.findIndex((el) => el === this)
			lines.splice(0, findIndex + 1)
			const endIndex = lines.findIndex((el) => el.dataset.speakerId)
			if (endIndex > -1) {
				lines.splice(endIndex)
			}
			lines.forEach((line) => line.render?.())
		}
	}
	#cleanClone(removeProb = false) {
		const clone = /** @type {TranscriptLine}*/ (this.cloneNode(true))
		clone.querySelectorAll("span.time, span.speaker").forEach((el) => {
			el.remove()
		})
		removeProb &&
			clone.querySelectorAll("t-prob").forEach((el) => {
				el.replaceWith(...el.childNodes)
			})
		return clone
	}
	/** @type {() => [string, string]} */
	toTextAndHTML() {
		let prefix = ""
		const classList = /**@type {DOMTokenList}*/ (this.parentElement?.classList)
		const withLineNumbers = classList.contains("display-line-numbers")
		const withSpeakers = classList.contains("display-speakers")
		if (withLineNumbers) {
			const siblings = this.parentElement ? Array.from(this.parentElement?.children) : []
			prefix = String(siblings.findIndex((el) => el === this) + 1).padStart(4, " ") + "| "
		}
		const speaker = this.speakerData
		const time = this.#timeElmt.innerText
		const name = withSpeakers && speaker.name ? ` ${speaker.name} : ` : ""
		const color = speaker.color ? `color:${speaker.color};` : ""
		const fontSize = `font-size:${TranscriptLine.exportFontSize};`
		const fontFamily = `font-family:${TranscriptLine.exportFontFamily};`
		const metaFontSize = `font-size:${TranscriptLine.exportMetaFontSize};`
		const metaFontFamily = `font-family:${TranscriptLine.exportMetaFontFamily};`
		const clone = this.#cleanClone(true)
		return [
			`${prefix}${time}${name}${spaceCollapse(noNbsp(clone.innerText)).trim()}`,
			/*html*/ `<div style="${fontSize}" class="transcript"><span class="metadata" style="${metaFontFamily}${metaFontSize}">${prefix}${time}</span><span style="${fontFamily}${fontSize}${color}">${name} ${noNbsp(
				clone.innerHTML
			)}</span></div>`,
		]
	}
	toRawHTML() {
		return this.#cleanClone(false).outerHTML
	}
}

customElements.define("transcript-line", TranscriptLine)
// @ts-ignore
window.TranscriptLine = TranscriptLine
