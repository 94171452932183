//@ts-check

import { app } from "./app.js"
import { setProp } from "./libs/tstorex/recipes.js"
import { models } from "./models.js"
import { appendTranscriptLine } from "./transcriptEditor.js"

/** @typedef {(url:string, dst:string, size_mb:number, cbProgress:Function, cbReady: Function, cbCancel:Function, cbPrint:Function)=>void }*/ loadRemote

const dfltSampleRate = 16000
const dfltMaxDuration = 60 * 60

/** @type {any} the whisper instance*/
let instance = null

/** @type {Promise<string>|null} promise of the model to use*/
let modelPromise = null
/** @type {Promise<Float32Array>|null} promise of audio to use */
let mediaPromise = null

const modelSelectEl = /** @type {HTMLSelectElement} */ (document.getElementById("modelSelect"))
const whisperStatusEl = /** @type {HTMLElement} */ (document.getElementById("model-whisper-status"))
const inputloadTranscript = /** @type {HTMLInputElement} */ (document.getElementById("load-transcript"))

//#region bind model select element
/** @param {string} text */
const setWhisperStatus = (text) => (whisperStatusEl.innerHTML = text)
modelSelectEl.innerHTML = '<option value="">Select a model</option>'
Object.keys(models).forEach((model) => {
	const optionEl = document.createElement("option")
	optionEl.value = model
	optionEl.innerText = models[model].label
	modelSelectEl.appendChild(optionEl)
})
modelSelectEl.addEventListener("change", () => {
	const model = modelSelectEl.value
	// disabled load transcript input
	inputloadTranscript.disabled = true
	setWhisperStatus("loading model: " + model)
	Whisper.loadModel(model)
		.then(() => {
			app.nextScreen()
		})
		.catch((reason) => {
			alert(reason)
		})
})
//#endregion bind model select element

///////////////// WASM MODULE SETTINGS ///////////////////////
//@ts-expect-error Module is global wasm module
window.Module = {
	/** @param {string} text */
	print: (text) => {
		if (!text.match(/^\[\d\d:\d\d:\d\d\.\d\d\d --> \d\d:\d\d:\d\d\.\d\d\d\]/)) {
			app.log("stdout: " + text)
		} else {
			appendTranscriptLine(text)
			app.log("stdout: " + text.replace(/<\/?t-prob[^>]*>/g, ""))
		}
	},
	/** @param {string} text */
	printErr: (text) => {
		// treat normal messages comming from whisper as errors
		// (they are not errors but we want to see them)
		if (text.match(/^whisper_(init|model|print)/)) {
			app.log("stderr: " + text)
			if (text.startsWith("whisper_print_timings:    total time") && app.isWorking()) {
				app.setWorkingState(false)
				app.log(`\\o/ Transcript done \\o/ You should save your work now !`)
				alert("Transcript done")
				//-@ts-expect-error Module is global wasm module
				// instance !== null && window.Module.free(instance)
				// instance = null
				// modelPromise = null
				// mediaPromise = null
			}
			return
		}
		app.logError("stderr: " + text)
	},
	printWithColors: true,
	/** @param {string|number} text */
	setStatus: function (text) {
		app.log("status: " + text)
	},
	/** @param {string|number} code */
	onExit: function (code) {
		//@todo(whiper) this code is never called investigate alternatives
		app.setWorkingState(false)
		console.log("onExit: Progam exit with code " + code)
	},
	/** @param {string} what */
	onAbort: function (what) {
		app.setWorkingState(false)
		alert(`Program aborted: ${what}

This can happen if the model is too big for your device or exhausting memory due to too many threads.
It also can be due to an audio/video file which is too heavy or in unsupported format.
Try either to reduce the number of threads or to use a smaller model.

The page will now be reloaded !`)
		window.location.reload()
	},
	// monitorRunDependencies: function (left) {},
}

/**
 * preload media file for treatment
 * @param {File} mediaFile
 * @param {{sampleRate?:number, maxDuration?:number, startAt?:number}} [options]
 * @returns {Promise<Float32Array>}
 */
const loadMedia = async (/** @type {File} */ mediaFile, options) => {
	const { sampleRate = dfltSampleRate, maxDuration = dfltMaxDuration, startAt = 0 } = options || {}
	// @TODO(whisper) close previously opened context if any or find a way to close when transcript is done
	app.log("js: loading audio from: " + mediaFile.name + ", size: " + mediaFile.size + " bytes", "js: please wait ...")
	const ctx = new AudioContext({ sampleRate })
	app.setWorkingState(true)
	return mediaFile
		.arrayBuffer()
		.then((arrayBuffer) => ctx.decodeAudioData(arrayBuffer))
		.then((audioBuffer) => {
			// const buf = new Uint8Array(arrayBuffer)
			var offlineContext = new OfflineAudioContext(
				audioBuffer.numberOfChannels,
				audioBuffer.length,
				audioBuffer.sampleRate
			)
			var source = offlineContext.createBufferSource()
			source.buffer = audioBuffer
			source.connect(offlineContext.destination)
			source.start(0)
			return offlineContext.startRendering()
		})
		.then(function (renderedBuffer) {
			let audio = renderedBuffer.getChannelData(0)
			app.log("js: audio loaded, size: " + audio.length)
			// trucate until startAt
			if (startAt && audio.length > startAt * sampleRate) {
				audio = audio.slice(startAt * sampleRate)
				app.logLevel("warn", "js: truncated audio to start at " + startAt + " seconds")
			}
			// truncate to first maxduration
			if (audio.length > maxDuration * sampleRate) {
				audio = audio.slice(0, maxDuration * sampleRate)
				app.logLevel("warn", "js: truncated audio to first " + maxDuration + " seconds")
			}
			return audio
		})
		.finally(() => {
			ctx.close()
			app.setWorkingState(false)
		})
}

// helper function
// function convertTypedArray(src, type) {
// 	var buffer = new ArrayBuffer(src.byteLength)
// 	var baseView = new src.constructor(buffer).set(src)
// 	return new type(buffer)
// }

/**
 * @param {string} fname
 * @param {Uint8Array} buf binary data /!\ type may be incorrect
 */
function storeFS(fname, buf) {
	//@ts-expect-error module is global
	const { FS_unlink, FS_createDataFile } = window.Module
	// write to WASM file using FS_createDataFile
	// if the file exists, delete it
	try {
		FS_unlink(fname)
	} catch (e) {
		// ignore
	}
	FS_createDataFile("/", fname, buf, true, true)
	setWhisperStatus(`loaded "${fname}"!`)
	app.log(`storeFS: stored model: ${fname} size: ${buf.length}`)
	//@ts-ignore
	document.getElementById("model").innerHTML = "Model fetched: " + fname
}

/**
 * load Whisper model
 * @param {string} model
 * @returns {Promise<string>} promise resolved with model name
 */
const loadModel = async (/** @type {string} */ model) => {
	if (!models?.[model]) {
		app.logLevel("error", "loadWhisper: invalid model: " + model)
		modelSelectEl.selectedIndex = 0
		return Promise.reject("Invalid whisper model")
	}
	app.setWorkingState(true)
	let url = `./models/ggml-model-whisper-${model.replace(/-en/, ".en")}.bin`
	let dst = "whisper.bin"
	let size_mb = models[model].size
	setProp(/** @type {any}*/ (app.settingsStore), "whisper.model", model)
	setWhisperStatus("loading model: '" + model + "'. Please wait...")
	modelSelectEl.disabled = true

	/** @param {number} p */
	const cbProgress = function (p) {
		let el = document.getElementById("fetch-whisper-progress")
		el && (el.innerHTML = Math.round(100 * p) + "%")
	}

	return new Promise((resolve, reject) => {
		const cbCancel = function () {
			modelSelectEl.selectedIndex = 0
			modelSelectEl.disabled = false
			setWhisperStatus("")
			reject("Error loading model")
		}

		// @ts-expect-error
		const cbReady = (...args) => {
			// @ts-expect-error
			storeFS(...args)
			resolve(model)
		}
		loadRemote(url, dst, size_mb, cbProgress, cbReady, cbCancel, app.log)
	}).finally(() => app.setWorkingState(false))
}

/**
 * perform the transcription
 * @param {Float32Array} audio
 * @param {{translate?:boolean, nbThreads?:number, startOffset?:number}} [options]
 */
const process = (audio, options) => {
	const { whisper, audio: audioSettings } = app.settingsStore.get()
	const { translate = false, nbThreads = whisper.threads, startOffset = 0 } = options || {}
	//@ts-expect-error Module is global
	const Module = /**@type {any} */ (window.Module)
	app.setWorkingState(true)
	if (!instance) {
		instance = Module.init("whisper.bin")

		if (instance) {
			app.log("js: whisper initialized, instance: " + instance)
			//@ts-ignore
			document.getElementById("model").innerHTML = "Model loaded: " + whisper.model
		}
	}

	if (!instance) {
		app.logLevel("error", "js: failed to initialize whisper")
		return
	}

	if (!audio) {
		app.logLevel("error", "js: no audio data")
		return
	}

	if (instance) {
		app.log("")
		app.log("js: processing - this might take a while ...")
		app.log("")
		app.lastScreen()
		setTimeout(function () {
			var ret = Module.full_default(instance, audio, audioSettings.lang, nbThreads, translate, startOffset)
			console.log("js: full_default returned: " + ret)
			if (ret) {
				app.log("js: whisper returned: " + ret)
			}
		}, 100)
	}
}
export class Whisper {
	/**
	 * load Whisper model
	 * @param {string} model
	 * @returns {Promise<string>}
	 */
	static async loadModel(model) {
		if (modelPromise === null) {
			modelPromise = loadModel(model)
			return modelPromise
		}
		const oldModelPromise = await modelPromise
		if (oldModelPromise === model) {
			return modelPromise
		}
		alert('You are trying to load model "'+model+'" while a different model is already loaded. Please reload the page to change model.')
		throw new Error("Different model already loaded")
		// modelPromise = loadModel(model)
	}
	/**
	 * preload media file for treatment
	 * @param {File} mediaFile
	 * @param {{sampleRate?:number, maxDuration?:number, startAt?:number, forceReload?:boolean}} [options]
	 * @returns {Promise<Float32Array>}
	 */
	static async loadMedia(mediaFile, options) {
		const { forceReload = false } = options || {}
		if (mediaPromise === null) {
			mediaPromise = loadMedia(mediaFile, options)
		} else if (forceReload && mediaPromise !== null) {
			//@TODO make promise cancelable to avoid wait of previous promise
			const oldMediaPromise = mediaPromise
			const loader = () => loadMedia(mediaFile, options)
			mediaPromise = oldMediaPromise.then(loader, loader)
		}
		return mediaPromise
	}
	/**
	 * perform the transcription
	 * @param {Float32Array} audio
	 * @param {{translate?:boolean, nbThreads?:number, startOffset?:number}} [options]
	 */
	static process(audio, options) {
		return process(audio, options)
	}
}

// window.Whisper = Whisper
